<template>
  <div class="remind-detail">
    <van-list v-model="loading" :finished="finished" @load="onLoad" :offset="100">
      <div>
        <div class="remind-list" v-for="(item, index) of remindList" :key="index">
          <div class="info">
            <div class="time-circle">
              <div class="time">
                <div class="day">{{ item.createTime | filterDay }}</div>
                <div class="hour">{{ item.createTime | filterHour }}</div>
              </div>
              <div class="circle"></div>
            </div>
            <div class="path-content">
              <div class="cell-title">
                <wxb-tag :color="showColor(item.msgType)">{{ showText(item.msgType) }}</wxb-tag>
              </div>
              <div class="label">{{ item.context }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import home from '@/api/home';
import dayjs from 'dayjs';

export default {
  name: 'remindDetail',
  data() {
    return {
      remindList: [],
      loading: false,
      finished: false,
      pageNation: {
        pageNo: 0,
        pageSize: 10,
      },
      totalRecords: 0,
    };
  },
  filters: {
    filterDay(val) {
      return dayjs(val).format('MM-DD');
    },
    filterHour(val) {
      return dayjs(val).format('HH:mm');
    },
  },
  methods: {
    onLoad() {
      this.pageNation.pageNo += 1;
      this.getMessageNum();
    },
    async getMessageNum() {
      let params = {
        opId: localStorage.getItem('platform_opId'),
        estateId: localStorage.getItem('platform_estateId'),
        pageNo: this.pageNation.pageNo,
        pageSize: this.pageNation.pageSize,
      };
      let { code, data, msg } = await home.getMsgPage(params);
      this.loading = false;
      if (code !== '0') {
        console.error('接口报错', msg);
        return;
      }
      this.remindList = this.remindList.concat(data.data);
      this.totalRecords = data.totalRecords;
      if (this.remindList.length >= this.totalRecords) {
        this.finished = true;
      }
    },
    showText(value) {
      switch (value) {
        case 101:
          return '跟进任务';
        case 102:
          return '跟进提醒';
        case 103:
          return '逾期通知';
        case 104:
          return '客户变更归属';
        case 105:
          return '分配客户';
        case 201:
          return '分配通知';
        case 202:
          return '归属变更';
        case 203:
          return '潜客转化';
        case 204:
          return '传播任务';
        case 205:
          return '雷达';
        case 206:
          return '员工删除好友提醒';
        case 207:
          return '流失预警';
        case 208:
          return '传播任务';
        case 209:
          return '好友添加任务';
        case 210:
          return '任务提醒';
        case 301:
          return '敏感词提醒';
        case 302:
          return '商机提醒';
        default:
          return '';
      }
    },
    showColor(value) {
      switch (value) {
        case 101:
          return '#4E5666';
        case 102:
          return '#3172FF';
        case 103:
          return '#FFA827';
        case 104:
          return '#6AD038';
        case 105:
          return '#4E5666';
        case 201:
          return '#3172FF';
        case 202:
          return '#FFA827';
        case 203:
          return '#6AD038';
        case 204:
          return '#3172FF';
        default:
          return '#3172FF';
      }
    },
    showBodText(value) {
      if (value) {
        return value.split(';');
      } else {
        return '无';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.remind-detail {
  padding: 12px 16px 40px 16px;

  .remind-list {
    margin-bottom: 24px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 24px;
      background: #e6e6e6;
      left: 53.7px;
      bottom: -24px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      background: #e6e6e6;
      left: 53.7px;
      top: 0;
    }
    &:first-child {
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 50%;
        background: #e6e6e6;
        left: 53.7px;
        top: 50%;
      }
    }
    &:last-child {
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 50%;
        background: #e6e6e6;
        left: 53.7px;
        top: 0px;
      }
      &::after {
        display: none;
      }
    }
    &:nth-last-child(1):first-child::before {
      display: none;
    }
    .info {
      width: 100%;
      display: flex;
      align-items: center;
      .time-circle {
        display: flex;
        align-items: center;
        .time {
          margin-right: 10px;
          text-align: right;
          .day {
            width: 40px;
            font-size: 12px;
            font-weight: bold;
            color: @gray3;
            line-height: 14px;
            white-space: nowrap;
          }

          .hour {
            margin-top: 2px;
            font-size: 12px;
            font-weight: 400;
            color: @gray9;
            line-height: 17px;
          }
        }
        .circle {
          margin-right: 10px;
          width: 5px;
          height: 5px;
          background: #e6e6e6;
          border-radius: 5px;
          border: 2px solid #f5f6f7;
          z-index: 2;
        }
      }
      .path-content {
        flex: 1;
        padding: 16px;
        background-color: @gary-white;
        border-radius: 4px;
        .cell-title {
          display: flex;
          align-items: center;

          .tag {
            height: 14px;
            padding: 3px 8px;
            background: rgba(30, 93, 233, 0.1);
            color: @blue;
            font-size: 10px;
            line-height: 14px;
            border-radius: 2px;
            margin-right: 8px;
          }
        }

        .label {
          margin-top: 7px;
          font-size: 14px;
          font-weight: 400;
          color: @gary-wxb-black-1;
          line-height: 20px;
          margin-bottom: 12px;
        }

        .text {
          font-size: 12px;
          font-weight: 400;
          color: @gary-wxb-gray-2;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
